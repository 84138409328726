import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Card,
  CardContent,
  IconButton,
  Box,
  Chip,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import axios from "axios";
import Heading from "../../Common/Header/Heading";
import { DEFAULTS } from "../../../../Constants/Constants";

function Customers() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("admin-token");
  const [dataFetched, setDataFetched] = useState(true);

  useEffect(() => {
    getRecords();
  }, []);

  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + "customers", {
        headers: { Authorization: `Bearer ${token}` },
      });

      let records = response.data;
      records = records.sort((a: any, b: any) => b.userid - a.userid);

      setRecords(records);
      setFilteredRecords(records);
      setDataFetched(false);
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  // Search filter function
  useEffect(() => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = records.filter((row) =>
      ["firstname", "lastname", "username", "company","mobile"].some((field) =>
        String(row[field] || "").toLowerCase().includes(lowercasedFilter)
      )
    );
    setFilteredRecords(filteredData);
  }, [searchText, records]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        field: "userid",
        headerName: "Customer Id",
        flex: 1,
        renderCell: (params: any) => (
          <a href={`/portal/customer/${params.row.userid}`}>{params.row.userid}</a>
        ),
      },
      {
        field: "firstname",
        headerName: "First Name",
        flex: 2,
        sortable: true,
        renderCell: (params: any) => params.row.firstname.toUpperCase(),
      },
      {
        field: "lastname",
        headerName: "Last Name",
        flex: 2,
        sortable: true,
        renderCell: (params: any) =>
          params.row.lastname ? params.row.lastname.toUpperCase() : "",
      },
      {
        field: "company",
        headerName: "Company Name",
        flex: 2,
        sortable: true,
        renderCell: (params: any) =>
          params.row.company ? params.row.company.toUpperCase() : "",
      },
      {
        field: "username",
        headerName: "Email",
        flex: 2,
        sortable: true,
      },
      {
        field: "mobile",
        headerName: "Phone",
        flex: 2,
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params: any) => (
          <Chip
            label={params.row.status}
            size="small"
            sx={{
              borderRadius: "50px",
              backgroundColor:
                params.row.status === "ACTIVE" ? "lightgreen" : "lightgray",
              color: params.row.status === "ACTIVE" ? "green" : "gray",
              fontWeight: "bold",
            }}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params: any) => (
          <Tooltip title="View Customer Details">
            <IconButton onClick={() => navigate(`/portal/customer/${params.row.userid}`)}>
              <PageviewIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [navigate]
  );

  return (
    <>
      <Heading icon={<GroupIcon color="secondary" />} text="Customers" />
      <Card variant="outlined">
        <CardContent>
          {/* Search Box */}
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <TextField
              label="Filter by Name, Email, Company Name"
              variant="outlined"
              fullWidth
              value={searchText}
              onChange={handleFilterChange}
            />
            <IconButton
              sx={{ color: "red" }}
              aria-label="quotes"
              onClick={() => navigate("/portal/customer/add")}
            >
              <GroupAddIcon />
            </IconButton>
          </Box>

          {/* Data Grid */}
          <DataGrid
            rows={filteredRecords} // ✅ Use filtered data
            columns={columns}
            autoHeight
            getRowId={(row: any) => row.userid}
            loading={dataFetched}
            style={{ marginBottom: 10 }}
            pageSizeOptions={[25, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 25, page: 0 } },
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default Customers;
