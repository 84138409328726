import React , {useState,useEffect} from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { Container, Grid,Box, TextField,Snackbar, Alert} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { logEvent } from "firebase/analytics";
import { DEFAULTS } from '../../../../Constants/Constants'



export default function QuoteComplete() {

  const navigate = useNavigate();
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const token = localStorage.getItem('admin-token');
  const [alert, setAlert] = React.useState<{ type: 'success' | 'error'; message: string; open: boolean }>({
      type: 'success',
      message: '',
      open: false,
    });

    const location = useLocation();
    const data = location.state;
    console.log("state", data)

    const handleClose = () => setAlert({ ...alert, open: false });
  const updateStatus = async (quote: any, setAlert: (alert: { type: 'success' | 'error'; message: string; open: boolean }) => void) => {
    const request = {
      quoteId: Number(quote?.id),
      status: "CUSTOMER REVIEW",
      emailTo: quote?.quoteDetails?.ship_email,
      dynamicData: {
        name: quote?.quoteDetails?.ship_name,
        amount: quote?.quoteDetails?.totalGrand,
        email:quote?.quoteDetails?.ship_email
      },
    };
  
    try {
      const response = await axios.post(`${DEFAULTS.API_URL}quote-status`, request,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        setAlert({ type: 'success', message: 'Quote sent successfully!', open: true });
      } else {
        setAlert({ type: 'error', message: 'Failed to update quote status.', open: true });
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setAlert({ type: 'error', message: 'An error occurred while updating the quote status.', open: true });
    }
  };
  
  const handleUpdateStatus = async () => {
    await updateStatus(data, setAlert);
  };

  return (
    <div>
        
    <Container>
    <Typography variant="h5">Quote Created </Typography>
        <Card variant="outlined">
          
           <Typography variant="body1"
           style={{ marginLeft: 20, marginTop: 20}}
           >Quote successfully created. Please send the quote to the shipper for review and approval.</Typography>

           <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20, marginLeft: 20}}
                    onClick={handleUpdateStatus}
                  >
                    Send Quote to Customer
                  </Button>
          </Card>
          
        </Container>
         <Snackbar open={alert.open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                  {alert.message}
                </Alert>
              </Snackbar>
        </div>

  );
}