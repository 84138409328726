import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography, Button} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import PageviewIcon from '@mui/icons-material/Pageview';
import Grid from '@mui/material/Grid'; // Grid version 1
import moment from 'moment';
import { Chip, Box } from '@mui/material';
import axios from 'axios';
import User from './User'; // Import the modal component
import Autocomplete from '@mui/material/Autocomplete';
import Heading from '../../Common/Header/Heading';
import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'
import GroupIcon from '@mui/icons-material/Group';


function Users() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  console.log('token', token)

  const handleOpen = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'users', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.username);
        let db: any = new Date(a.username);
        return da - db;
      });
  
  
      setRecords(records);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      { field: 'username', operator: 'contains', value: '' },
      { field: 'userProfileName', operator: 'contains', value: '' },
      { field: 'mobile', operator: 'contains', value: '' },
       // Correct property
    ],
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterModel({
      items: [
        { field: 'username', operator: 'contains', value: e.target.value },
        { field: 'userProfileName', operator: 'contains', value: e.target.value },
        { field: 'mobile', operator: 'contains', value: e.target.value },
      
      ],
    });
  };

  const columns = [
    {
      field: "userid",
      headerName: "User Id",
      flex: 1,
      sortable: true,
      filter:false
    },
    {
      field: "userProfileName",
      headerName: "Profile Name",
      flex: 2,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.userProfileName ? params.row.userProfileName.toUpperCase() : ''
      )
    },
    {
      field: "username",
      headerName: "Username",
      flex: 2,
      sortable: true,
      filter:false
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      sortable: true,
      filter:false
    },
    {
      field: "commission",
      headerName: "Commission %",
      flex: 1,
      sortable: true,
      filter:false
    },
    {
      field: "country",
      headerName: "Country",
      flex: 2,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.country ? params.row.country.toUpperCase() : ''
      )
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params: any) => (
        <Chip
      label={params.row.status}
      size="small"
      sx={{
        borderRadius: '50px',
        backgroundColor:
          params.row.status === 'ACTIVE' ? 'lightgreen' : 'lightgray',
        color: params.row.status === 'ACTIVE' ? 'green' : 'gray',
        fontWeight: 'bold',
      }}
    />
        
      ),
    },
    {
      field: "commissionbalance",
      headerName: "Balance",
      flex: 1,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.commissionbalance ? `$${Number(params.row.commissionbalance).toFixed(2)}` : '$0'
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title="View Agent/User Details">
  <IconButton onClick={() => handleOpen(params.row)}>
    <PageviewIcon  />
  </IconButton>
</Tooltip>
        
      ),
    },
   
  ];

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Heading icon={<GroupIcon color="secondary" />} text="Users" />
    <Card variant="outlined">
        <CardContent>

        <Box sx={{ marginBottom: 2 }}>
        <TextField
          label="Filter by Name, Email, mobile"
          variant="outlined"
          fullWidth
          onChange={handleFilterChange}
        />
      </Box>
       <DataGrid
        rows={records}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.userid}
        loading={dataFetched}       
        style={{marginBottom:10}}
        filterModel={filterModel} // Apply the filter model
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        pageSizeOptions={[25, 50, 100]} // Options for user selection
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 }, // Default to 50 rows
          },}}
      />
      <User
        open={open}
        onClose={handleClose}
        row={selectedRow}
      />
          </CardContent>
          </Card>
</>
    );
  }

  

  export default Users;