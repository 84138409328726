import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import pic from '../../../../assets/images/ikeocean-300x60white.png';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useSelector } from 'react-redux'
import { updateProfile,
  getProfile,
  selectProfile } from '../../../../features/profile/profileSlice'

  type customerProfileType = {
    [key: string]: any;
  }
  
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#000000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#f44336',
    },
  },
});

function Header() {
  let CUSTOMERPROFILE : customerProfileType = JSON.parse(JSON.stringify(useAppSelector(selectProfile)))
  const dispatch = useAppDispatch();
  console.log("CUSTOMERPROFILE", CUSTOMERPROFILE, CUSTOMERPROFILE.firstname)
  const navigate = useNavigate();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAuth(event.target.checked);
    };
  
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = () => {
      console.log("logout")
      handleClose()
      localStorage.removeItem('admin-token');
      navigate('/auth/logout')
    };
    const handleProfile = () => {
      console.log("logout")
      handleClose()
      navigate('/portal/profile')
    };
    const handleCompany = () => {
      console.log("logout")
      handleClose()
      navigate('/portal/company')
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          {/* Logo */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={pic} alt="Logo" style={{ height: '40px' }} />
          </Typography>

          {/* Icons on the right */}
          <IconButton 
            sx={{ color: 'red' }} // Red icon color
            aria-label="customer" 
            onClick={() => navigate('/portal/customer/add')} // Navigate to /customer
          >
            <GroupAddIcon />
          </IconButton>
          <IconButton 
            sx={{ color: 'red' }} // Red icon color
            aria-label="quotes" 
            onClick={() => navigate('/portal/quote/create')} // Navigate to /quote
          >
            <PostAddIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
    );
  }

  

  export default Header;