import React, {useState, useEffect, useRef } from 'react';
import {Container, FormControl, FormHelperText, Button,Card,Alert, CardContent,Box,Select, MenuItem} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { HostedForm, HostedFormDispatchDataResponse } from 'react-acceptjs';
import { useAcceptJs } from 'react-acceptjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import { DEFAULTS } from '../../../../Constants/Constants'
import Heading from '../../Common/Header/Heading';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { getDate, sortByDate, convertFirestoreTimestampToDate } from '../../../../util/DisplayUtils';
import { Formik, Form, Field } from 'formik';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';



function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}

const authDataProd = { //prod
  apiLoginID: '5shgWG8V5j',
  clientKey: '4Xqe2Ar9VCp6cZZy56gfZ7dr5r555KZM7M4BXB32Ta58AgFDdeSbJ9q5cwV2QK84',
  //clientKey: '28u2H8B4VanT3az3',
};

const authDataTest = {  
  apiLoginID: '2d4Bq8L4',
  clientKey: '387abB5JbfVP975s6x9qZt4EsVxPS7Nfvp8us7qTWSeWtCVw9Tgk9sQNNmAp34s5',
  //clientKey: '4C6js4w5E228E2mf',
};


type BasicCardInfo = {
  cardNumber: string;
  cardCode: string;
  month: string;
  year: string;
};
type shipmentOrderType = {
  [key: string]: any;
}

type walletType = {
  [key: string]: any;
}
function Commission() {
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const shipment = useSelector((state: RootState) => state.shipmentOrder.value)
  const [response, setResponse] = React.useState<string>('');
  const [wallet, setWallet] = React.useState([]);
  const [walletAmount, setWalletAmount] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [amountValid, setAmountValid] = React.useState(false);
  const [walletRow, setWalletRow] = React.useState<walletType>();
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [walletBalance, setWalletBalance] = React.useState(0);
  const token = localStorage.getItem('admin-token');
  const dispatch = useAppDispatch();
  
  const [commission, setCommission] = React.useState(0);
  const [commissionNew, setCommissionNew] = React.useState([]);
  const [commissionOld, setCommissionOld] = React.useState([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [isalert, setAlert] = useState({
    status:'',
    show:false
  });

 const [value, setValue] = React.useState('1');

 const handleChange = (event: React.SyntheticEvent, newValue: string) => {
   setValue(newValue);
 };


 


 const walletTotal = (wallet:[]) => {
  const total = wallet.reduce((accumulator, currentValue) => 
    (currentValue['commissionType'] ==='CREDIT' ? accumulator + Number(currentValue['commission']) : accumulator - Number(currentValue['commission'])), 0);
  console.log("total", total)


  setWalletBalance(total);
};
 
 const getWalletDetails = (walletRow:{})=>{
  console.log("walletRow", walletRow)
  setWalletRow(walletRow)
  setOpenDetail(true)
 }

 const getWallet = async () => {
  await axios.get(DEFAULTS.API_URL + 'agent/commission/transactions',
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      //walletResponse = response.data
      setCommissionNew(
        sortByDate(
          response.data.filter((item: any) => item.commissionStatus === 'REQUESTED'),
          'createdate'
        )
      );
      setCommissionOld(
        sortByDate(
          response.data.filter((item: any) => item.commissionStatus === 'COMPLETED'),
          'createdate'
        )
      );
     console.log("wallet", response.data)
     walletTotal(response.data)
    }
  ) 
    
}
interface WalletRow {
  commissionid: number;
  commissionStatus: string;
  commission: number;
  commissionType: string;
  commissionOrder?: string;
  bankDetails?: {
    bankName: string;
    bankingName: string;
    bankAccNo: string;
    bankRoute: string;
    bankSwiftCode: string;
  };
  walletType?: string;
}

const getCommissionDetails = (walletRow:{})=>{
  console.log("walletRow", walletRow)
  setWalletRow(walletRow)
  
  setOpenDetail(true)
 }

const getValue = () => {
  
  if (textFieldRef.current) {
    console.log("getValue", textFieldRef.current.value)
    return Number(textFieldRef.current.value);
  }
  return 0;
};

  
useEffect(() => {
    getWallet()

  },[]);


  const formikCommission = useFormik({
    initialValues: {
      commissionid: walletRow?.commissionid || "",
      commissionStatus: "Select Status",
      transactionDetails: {
        transactionDate: '',
        transactionNumber: '',
      },
    },
    validationSchema: yup.object({
      commissionStatus: yup
        .string()
        .required('Commission status is required')
        .oneOf(['COMPLETED'], 'Invalid status value'),
      transactionDetails: yup.object().shape({
        transactionNumber: yup
          .string()
          .required('Transaction number is required')
          .matches(/^[A-Za-z0-9_-]+$/, 'Invalid transaction number format'),
      }),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const finalValues = {
        ...values,
        commissionid: walletRow?.commissionid, // Explicitly add it if missing
      };
      console.log(finalValues); // Debugging
       // 
      axios
        .put(`${DEFAULTS.API_URL}agent/commission`, finalValues, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // Handle success response
          setFormError(null); // Clear any previous error
          formikCommission.resetForm(); // Reset the form
          getWallet(); // Refresh commission data
          setOpenDetail(false)
          setAlert({
            status : 'success',
            show: true
          })
        })
        .catch((error) => {
          // Handle error response
          setFormError(
            error.response?.data?.message || 'An unexpected error occurred. Try again'
          );
         
        });
    },
  });
  




    return (
<>
<Heading icon={<MonetizationOnIcon color="secondary" />} text="Commission" />
            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    
                  }}
                >
                  <React.Fragment>

      <Typography component="p" variant="h4">
        ${Number(walletBalance).toFixed(2)|| 0}
      </Typography>
      </React.Fragment>
      </Paper>
      </Grid>
      </Grid>

      <Grid container spacing={3} style={{marginTop:10}}>

<Grid item xs={12} md={12} lg={12} >
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      
    }}
  >
    <React.Fragment>

    {(isalert?.show) && <Alert severity={isalert.status  as "error" | "info" | "success" | "warning"}> 
      
      {isalert.status === "error" && `An error occurred. Please try again`}
      {isalert.status === "success" && `The commission payout has been completed successfully`}
      </Alert>}

    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="New Requests" value="1" />
            <Tab label="PAID" value="2" />

          </TabList>
        </Box>
        <TabPanel value="1">
          
        {(commissionNew.length > 0) ? (
        <Table size="small">
          <TableHead>
          <TableRow>
          <TableCell>Order</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Agent Name</TableCell>
            <TableCell>Amount</TableCell>

            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(commissionNew?.map((row: any, idx: any) =>  (
             <>
            <TableRow key={row['_id']}>
              <TableCell>{row['commissionid']}</TableCell>
<TableCell>{convertFirestoreTimestampToDate(row['createdate'])}</TableCell>
<TableCell>{row['commissionName']}</TableCell>
             
              <TableCell style={{color:'red'}}>${row['commission']}</TableCell>
              <TableCell>{row['commissionStatus']}</TableCell>
              <TableCell><Button type="button" variant="text" onClick={()=>getCommissionDetails(row)}>Details</Button></TableCell>
            </TableRow>
          
            </>
          
    )))}
    </TableBody>
    </Table>) : <> No records found</>}




        </TabPanel>
        <TabPanel value="2">

        {(commissionOld.length > 0) ? (
        <Table size="small">
          <TableHead>
          <TableRow>
          <TableCell>Order</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Agent Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Paid Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(commissionOld?.map((row: any, idx: any) =>  (
             <>
            <TableRow key={row['_id']}>
              <TableCell>{row['commissionid']}</TableCell>
              <TableCell>{convertFirestoreTimestampToDate(row['createdate'])}</TableCell>
              <TableCell>{row['commissionName']}</TableCell>
              <TableCell style={{color:'red'}}>${Number(row['commission']).toFixed(2)}</TableCell>
              <TableCell>{row['commissionTransaction']?.transactionDate && convertFirestoreTimestampToDate(row['commissionTransaction']?.transactionDate)}</TableCell>
              <TableCell>{row['commissionStatus'] === "COMPLETED" && 'PAID'}</TableCell>
              <TableCell><Button type="button" variant="text" onClick={()=>getCommissionDetails(row)}>Details</Button></TableCell>
            </TableRow>
          
            </>
          
    )))}
    </TableBody>
    </Table>) : <> No records found</>}


        </TabPanel>

      </TabContext>
    </Box>



      
      



            </React.Fragment>
      </Paper>
      </Grid>
      </Grid>
      
            

      <Modal
  open={openDetail}
  onClose={handleCloseDetail}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"  
>
<Box sx={style} component="form" onSubmit={formikCommission.handleSubmit}>
  <Typography id="modal-modal-title" variant="h6" component="h2">
    Transaction Details
  </Typography>
  <hr />
  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <p>Transaction Id: {walletRow?.commissionid}</p>
    {/* <p>Date: {convertFirestoreTimestampToDate(walletRow?.createdate)}</p> */}
    <p>Amount: ${walletRow?.commission}</p>
    <p>Transaction: {walletRow?.commissionType}</p>
    <p>Status: {walletRow?.commissionStatus}</p>
    {walletRow?.walletType === 'CREDIT' && (
      <p>Order: {walletRow?.commissionOrder}</p>
    )}
    <hr />
    <span>Bank Name: {walletRow?.bankDetails?.bankName}</span>
    <br />
    <span>Payee Name: {walletRow?.bankDetails?.bankingName}</span>
    <br />
    <span>Account Number: {walletRow?.bankDetails?.bankAccNo}</span>
    <br />
    <span>Routing Number: {walletRow?.bankDetails?.bankRoute}</span>
    <br />
    <span>Swift Code: {walletRow?.bankDetails?.bankSwiftCode}</span>
    <hr />
  {(walletRow?.commissionStatus === "COMPLETED") ?
   <>
   <span>Transaction Number: {walletRow?.commissionTransaction?.transactionNumber}</span>
    <br />
    <span>Paid On: {convertFirestoreTimestampToDate(walletRow?.commissionTransaction?.transactionDate)}</span>
    <br />

   </> :
   <>

   <TextField id="commissionid"
    label="Commission Transaction Id"
    value={walletRow?.commissionid}
    size="small"
    fullWidth
    disabled
    />
    <TextField
      margin="normal"
      name="transactionDetails.transactionNumber"
      label="Bank Transaction Number"
      type="text"
      id="transactionNumber"
      size="small"
      fullWidth
      value={formikCommission.values.transactionDetails.transactionNumber}
      onChange={formikCommission.handleChange}
      onBlur={formikCommission.handleBlur}
      error={
        formikCommission.touched.transactionDetails?.transactionNumber &&
        Boolean(formikCommission.errors.transactionDetails?.transactionNumber)
      }
      helperText={
        formikCommission.touched.transactionDetails?.transactionNumber &&
        formikCommission.errors.transactionDetails?.transactionNumber
      }
    />

<FormControl
  fullWidth
  error={formikCommission.touched.commissionStatus && Boolean(formikCommission.errors.commissionStatus)}
  size="small"
>
  <Select
    id="commissionStatus"
    name="commissionStatus"
    label="Status"
    onChange={formikCommission.handleChange}
    value={formikCommission.values.commissionStatus}
    onBlur={formikCommission.handleBlur}
    style={{
      width: '50%',
      borderRight: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    }}
  >
    <MenuItem value="Select Status">Select Status</MenuItem>
    <MenuItem value="COMPLETED">PAID</MenuItem>
  </Select>

  {/* Error message display */}
  {formikCommission.touched.commissionStatus && formikCommission.errors.commissionStatus && (
    <FormHelperText>{formikCommission.errors.commissionStatus}</FormHelperText>
  )}
</FormControl>
                  
    <hr />
    </>}
  </Typography>
  {(walletRow?.commissionStatus !== "COMPLETED")
   && <Button
    type="submit"
    variant="contained"
    sx={{ mt: 3, mb: 2 }}
    style={{ backgroundColor: '#eb4034' }}
    
  >
    Update & Approve
  </Button>}
 
  <Button
    variant="contained"
    sx={{ mt: 3, mb: 2 }}
    style={{ marginLeft: 20 }}
    onClick={() => setOpenDetail(false)}
  >
    Close
  </Button>
</Box>

</Modal>

</>
    );
  }

  

  export default Commission;

  