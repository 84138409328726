import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Button, IconButton,Typography,Tooltip} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet, useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';
import { Chip, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import PageviewIcon from '@mui/icons-material/Pageview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import axios from 'axios';
import Heading from '../../Common/Header/Heading';
import Autocomplete from '@mui/material/Autocomplete';
import {convertFirestoreTimestampToDate} from '../../../../util/DisplayUtils'
import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'


type customerType = {
  [key: string]: any;
}

const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
    return "S1";
      case 'local-courier':
        return "L1";
    default:
      return "T1";
  }
};
function Customer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [record, setRecords] =useState<customerType>();
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + `customer/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
     
  
      setRecords(response.data[0]);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }
// Quote Columns
const quoteColumns = [
  {
    field: "quoteId",
    headerName: "OrderId",
    width: 100,
    renderCell: (params: any) => (

   <a href={`/portal/quote/${params.row.quoteId}`}>{pre(params.row.quoteType) + params.row.quoteId}</a>
    )
  },
  {
    field: "quoteDate",
    headerName: "Date",
    width: 200,
    sortable: true,
    filter:false,
    renderCell: (params: any) => (
      convertFirestoreTimestampToDate(params.row.quoteDate)
    )
  },
  {
    field: "quoteName",
    headerName: "Name",
    width: 200,
    sortable: true,
    filter:false,
    renderCell: (params: any) => (
      params.row.quoteName.toUpperCase()
    )
  },
  {
    field: "quoteEmail",
    headerName: "Email",
    width: 200,
    sortable: true,
    filter:false
  },
  {
    field: "quoteMobile",
    headerName: "Mobile",
    width: 100,
    sortable: true,
    filter:false
  },
  {
    field: "quoteType",
    headerName: "Type",
    width: 100,
    sortable: true,
    filter:false,
    renderCell: (params: any) => (
      params.row.quoteType.toUpperCase()
    )
  },
  {
    field: "quoteAssigned",
    headerName: "Assigned To",
    width: 200,
    sortable: true,
    filter:false
  },
  {
    field: "quoteStatus",
    headerName: "Status",
    width: 100,
    renderCell: (params: any) => (
      <Chip
label={params.row.quoteStatus}
size="small"
sx={{
  borderRadius: '50px',
  backgroundColor: (() => {
    switch (params.row.quoteStatus) {
      case 'COMPLETED':
        return 'green';
      case 'NEW':
        return 'orange';
        case 'IN PROGRESS':
        return 'lightgreen';
      case 'REJECTED':
        return 'lightcoral';
      default:
        return 'lightgray'; // Default background color for other statuses
    }
  })(),
  
  fontWeight: 'bold',
}}
/>

      
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params: any) => (
      <Tooltip title="View Quote">
<IconButton onClick={() => navigate(`/portal/quote/${params.row.quoteId}`)}>
  <PageviewIcon  />
</IconButton>
</Tooltip>
      
    ),
  },
];

// order columns

const orderColumns = [
  {
    field: "orderId",
    headerName: "Order #",
    width: 100,
    renderCell: (params: any) => (

   <a href={`/portal/order/view/${params.row.orderId}`}> {params.row.orderId}</a>
    )
  },
  {
    field: "orderStatus",
    headerName: "Status",
    width: 100,
    renderCell: (params: any) => (
      <Chip
label={params.row.orderStatus}
size="small"
sx={{
  borderRadius: '50px',
  backgroundColor: (() => {
    switch (params.row.orderStatus) {
      case 'COMPLETE':
        return 'lightgreen';
      case 'NEW':
        return 'orange';
        case 'IN PROGRESS':
        return 'mediumgreen';
      case 'CANCELLED':
        return 'lightcoral';
      default:
        return 'lightgray'; // Default background color for other statuses
    }
  })(),
  
  fontWeight: 'bold',
}}
/>

      
    ),
  },
  {
    field: "orderDate",
    headerName: "Date",
    width: 200,
    sortable: true,
    filter:false,
    renderCell: (params: any) => (
      convertFirestoreTimestampToDate(params.row.orderDate)
    )
  },
  {
    field: "orderPaymentId",
    headerName: "Payment Id",
    width: 200,
    sortable: true,
    filter:false
  },

  {
    field: "orderPaymentStatus",
    headerName: "Payment Status",
    width: 200,
    renderCell: (params: any) => (
      <Chip
label={params.row.orderPaymentStatus}
size="small"
sx={{
  borderRadius: '50px',
  backgroundColor: (() => {
    switch (params.row.orderPaymentStatus) {
      case 'PAYMENT COMPLETE':
        return 'green';
      case 'PENDING PAYMENT':
        return 'orange';
        case 'PAYMENT FAILED':
        return 'red';
      case 'CANCELLED':
        return 'lightcoral';
      default:
        return 'lightgray'; // Default background color for other statuses
    }
  })(),
  
  fontWeight: 'bold',
}}
/>

      
    ),
  },
  
  {
    field: "quoteId",
    headerName: "Quote #",
    width: 100,
    sortable: true,
    filter:false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params: any) => (
      <Tooltip title="View Order Details">
<IconButton onClick={() => navigate(`/portal/order/view/${params.row.orderId}`)}>
  <PageviewIcon  />
</IconButton>
</Tooltip>
      
    ),
  },
  
];




    return (
<>

<Heading icon={<GroupIcon color="secondary" />} 
text={`${record?.firstname.toUpperCase()} ${record?.lastname.toUpperCase()}`} />
    <Card variant="outlined">
        <CardContent>
        <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">Customer Summary</Typography>
        </AccordionSummary>
        <AccordionDetails>



        <Table size="small">
        <TableBody>
        <TableRow>
          <TableCell> Name: </TableCell>
          <TableCell>{record?.firstname.toUpperCase()} {record?.lastname.toUpperCase()}</TableCell>
        </TableRow>
        <TableRow><TableCell>Email: </TableCell><TableCell>{record?.username.toUpperCase()}</TableCell></TableRow>
        <TableRow><TableCell>Company: </TableCell><TableCell>{record?.company.toUpperCase()}</TableCell></TableRow>
        <TableRow><TableCell>Customer Id:</TableCell><TableCell>{record?.userid}</TableCell></TableRow>
        <TableRow><TableCell>Mobile:</TableCell><TableCell>{record?.mobile}</TableCell></TableRow>
        <TableRow> <TableCell>Status: </TableCell><TableCell>
        <Chip
      label={record?.status}
      size="small"
      sx={{
        borderRadius: '50px',
        backgroundColor:
        record?.status === 'ACTIVE' ? 'lightgreen' : 'lightgray',
        color: record?.status.status === 'ACTIVE' ? 'green' : 'gray',
        fontWeight: 'bold',
      }}
    />
          
          </TableCell></TableRow>
          {/* <TableRow><TableCell></TableCell><TableCell><Button variant='contained'>Send password reset</Button> <Button variant='contained'>Send verification</Button></TableCell></TableRow> */}
        </TableBody>
          </Table>
          </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">Quotes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {(record?.quotes?.length >0) ? <DataGrid
                  rows={record?.quotes}
                  columns={quoteColumns}
                  autoHeight
                  getRowId={(row: any) => row.quoteId}
     
                  style={{marginBottom:10}}

                /> :<>No Quotes Found</>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">Orders</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {(record?.orders?.length > 0) ? <DataGrid
                  rows={record?.orders}
                  columns={orderColumns}
                  autoHeight
                  getRowId={(row: any) => row.orderId}
     
                  style={{marginBottom:10}}

                 /> : <> No Orders Found</> }
          </Typography>
        </AccordionDetails>
      </Accordion>
          </CardContent>
          </Card>
</>
    );
  }

  

  export default Customer;