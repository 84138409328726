import React, {useState, useEffect} from 'react';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageviewIcon from '@mui/icons-material/Pageview';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import Title from './Title';
import { Chip, Box } from '@mui/material';
import { DEFAULTS } from '../../../../Constants/Constants'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {convertFirestoreTimestampToDate} from './../../../../util/DisplayUtils'



function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}


const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
    return "S1";
      case 'local-courier':
        return "L1";
    default:
      return "T1";
  }
};


export default function QuoteCard() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  console.log('token', token)

  const columns = [
    {
      field: "quoteId",
      headerName: "Quote Id",
      flex: 1,
      renderCell: (params: any) => (
  
     <a href={`/portal/quote/assign/${params.row.quoteId}`}>{pre(params.row.quoteType) + params.row.quoteId}</a>
      )
    },
    {
      field: "quoteDate",
      headerName: "Date",
      flex: 2,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        convertFirestoreTimestampToDate(params.row.quoteDate)
      )
    },
    {
      field: "quoteName",
      headerName: "Name",
      flex: 2,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        `${params.row.quoteDetails?.ship_name?.toUpperCase() || ''} ${params.row.quoteDetails?.ship_lastname?.toUpperCase() || ''}`
      )
    },
    // {
    //   field: "quoteEmail",
    //   headerName: "Email",
    //   flex: 2,
    //   sortable: true,
    //   filter:false
    // },
    // {
    //   field: "quoteMobile",
    //   headerName: "Mobile",
    //   flex: 1,
    //   sortable: true,
    //   filter:false
    // },
    {
      field: "quoteType",
      headerName: "Type",
      flex: 1,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.quoteType.toUpperCase()
      )
    },
    // {
    //   field: "quoteAssigned",
    //   headerName: "Assigned To",
    //   flex: 2,
    //   sortable: true,
    //   filter:false
    // },
    {
      field: "quoteStatus",
      headerName: "Status",
      flex: 2,
      renderCell: (params: any) => (
        <Chip
  label={(() => {
    switch (params.row.quoteStatus) {
      case 'COMPLETED':
        return 'Completed';
      case 'NEW':
        return 'New Quote Request';
        case 'IN PROGRESS':
        return 'In Progress';
        case 'CUSTOMER REWORK':
          return 'Shipper Requested Revisions – Action Required';
      case 'REJECTED':
        return 'REJECTED';
        case 'AGENT ASSIGNED':
          return 'Quote Pending Processing';
          case 'CUSTOMER REVIEW':
            return 'Quote Sent – Awaiting Shipper Response';
      default:
        return 'lightgray'; // Default background color for other statuses
    }
  })()} //{params.row.quoteStatus}
  size="small"
  sx={{
    borderRadius: '50px',
    backgroundColor: (() => {
      switch (params.row.quoteStatus) {
        case 'COMPLETED':
          return 'green';
        case 'NEW':
          return 'orange';
        case 'IN PROGRESS':
          return 'lightgreen';
        case 'REJECTED':
          return 'lightcoral';
        case 'AGENT ASSIGNED':
          return 'lightblue';
        case 'CUSTOMER REWORK':
          return 'lightyellow';
        default:
          return 'lightgray'; // Default background color for other statuses
      }
    })(),
    
    fontWeight: 'bold',
  }}
  />
  
        
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: any) => (
        <Tooltip title="View Quote">
  <IconButton onClick={() => navigate(`/portal/quote/assign/${params.row.quoteId}`)}>
    <PageviewIcon  />
  </IconButton>
  </Tooltip>
        
      ),
    },
  ];


  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'quotes/new', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.quoteId);
        let db: any = new Date(a.quoteId);
        return da - db;
      });
  
  
      setRecords(records);
      //setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();

  },[]);

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }
  return (
    <React.Fragment>
      <Title>Recent Quotes ({records.length})</Title>

      <DataGrid
        rows={records}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.quoteId}
        style={{ marginBottom: 10 }}
        pageSizeOptions={[25, 50, 100]} // Options for user selection
  initialState={{
    pagination: {
      paginationModel: { pageSize: 25, page: 0 }, // Default to 50 rows
    },}}
      />
      {/* <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Quote Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Type</TableCell>

            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {records?.slice(0,15).map((row: any, idx: any) =>  (
            <TableRow key={row['_id']}>
              <TableCell>
              <Link color="primary" href={`/portal/quote/assign/${row['quoteId']}`} >{row['quoteId']}</Link></TableCell>
              <TableCell>{convertFirestoreTimestampToDate(row['quoteDate'])}</TableCell>
              <TableCell>{`${row['quoteDetails']?.ship_name?.toUpperCase() || ''} ${row['quoteDetails']?.ship_lastname?.toUpperCase() || ''}`}</TableCell>
              <TableCell>{row['quoteType'].toUpperCase()}</TableCell>
              <TableCell>
  
<Chip
  label="New Quote Request" //{row['quoteStatus'].toUpperCase()}
  size="small"
  sx={{
    borderRadius: '50px',
    backgroundColor: 'orange',
    
    fontWeight: 'bold',
  }}
/>
  </TableCell>
              <TableCell><Tooltip title="View Quote">
<Link color="primary" href={`/portal/quote/assign/${row['quoteId']}`} > 
    <PageviewIcon  /> </Link>

</Tooltip></TableCell>
              

              
            </TableRow>
          ))} 
        </TableBody>
      </Table> */}
     
    </React.Fragment>
  );
}